<template>
    <section class="container-fluid obras-ver-galeria custom-scroll overflow-auto">
        <titulo-divisor titulo="Galería" class="mx-0">
            <div class="row">
                <div class="col-auto  pl-1">
                    <button class="btn btn-general f-12 px-3" @click="crearCarpeta">Crear carpeta</button>
                </div>
            </div>
        </titulo-divisor>
        <div class="row">
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                <div class="br-4 border">
                    <p class="text-general f-14 f-600 text-center py-3 border-bottom-g mb-2 shadow29">Carpetas Galería</p>
                    <div class="listado-carpetas px-2 pb-3 overflow-auto custom-scroll mr-1 mb-2">
                        <card-folder v-for="data in foldersList" :key="data.id" :class="`${idFolderSelected == data.id ? 'selected' : ''}`"
                        :folderName="data.nombre"
                        :cantFiles="data.cantidad_archivos"
                        @editFolder="editFolder(data)"
                        @deleteFolder="deleteFolder(data.id)"
                        @action="loadFolderFiles(data.id)"
                        />
                    </div>
                </div>    
            </div>
            <div class="col-lg-8 mb-3 mb-lg-0">
                <div class="br-4 border">
                    <div class="row border-bottom-g f-600 f-14 mx-0 mb-2 shadow29 justify-content-between" style="height:54px">
                        <div class="col-auto">
                            <p class="py-3">Archivos</p>
                        </div>
                        <div class="col-auto my-auto text-center icon-option">
                            <i v-if="idFolderSelected != null" class="icon-plus-box f-20 cr-pointer" @click="agregarImg" />
                        </div>
                    </div>
                    <div class="listado-galeria px-2 py-3 overflow-auto custom-scroll mr-1 mb-2 ">
                        <div class="row mx-0">
                            <div v-for="(data,i) in selectedFolderFiles" :key="i" class="col-auto mb-3 px-1">
                                <div class="images br-5 cr-pointer d-middle-center" @click.stop="verGaleria(i)">
                                     <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                                        <template #content>
                                            <p class="px-1 py-2 cr-pointer item d-none" @click="editImg(data)">Editar</p>
                                            <p class="px-1 py-2 cr-pointer item" @click="deleteImg(data.id)">Eliminar</p>
                                        </template>
                                        <i class="icon-dots-vertical d-none" />
                                    </el-tooltip>
                                    <img :src="data.archivo | helper-storage" alt="" class="wh-117">
                                    <div class="hover-card text-white f-20 wh-117 d-middle-center">
                                        <i class="icon-eye" @click="verGaleria(i)" />
                                        <i class="icon-trash-can-outline" @click.stop="deleteImg(data.id)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
        <!-- Partials -->
        <modal-agregar-carpeta  ref="abrirAgregarCarpeta" />
        <modal-eliminar ref="abrirEliminarCarpeta" title="Eliminar carpeta" mensaje="¿Está seguro que quiere eliminar esta carpeta?" @delete="handleDeleteFolder" />
        <modal-eliminar ref="abrirEliminarImagen" title="Eliminar imágen" mensaje="¿Está seguro que quiere eliminar esta imágen?" @delete="handleDeleteFolderFile" />
        <modal-add-img ref="abrirAgregarImagenUnica" @addImage="handleAddImgFile" @editImage="handleEditImgFile" />
        <modal-image-gallery ref="openModalGallery" />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'ObrasGaleria',
    components: { 
        modalAgregarCarpeta: () => import('../partials/modalAgregarCarpeta'),
    },
    data(){
        return{
            visibleEditarArchivo: false,
            inputNombreArchivo: '',
            idGallery: this.$route.params.id,
            idFolderSelected: null,
            selectedFolderFiles: [],
            fileToDelete: ''
        }
    },
    async mounted () {
        await this.listFolders()
    },
    computed: {
        ...mapGetters({
            foldersList: 'obras/gallery/foldersList',
            folderFilesList: 'obras/gallery/folderFilesList',
            key: 'files/files/key'
        }),
    },
    methods:{
        ...mapActions({
            Action_get_folders: 'obras/gallery/Action_get_folders',
            Action_delete_folder: 'obras/gallery/Action_delete_folder',
            Action_get_folders_files: 'obras/gallery/Action_get_folders_files',
            Action_delete_folder_file: 'obras/gallery/Action_delete_folder_file',
            uploadFile: 'files/files/Action_save_file_to_aws',
            Action_save_folder_file: 'obras/gallery/Action_save_folder_file',
        }),
        async listFolders(){
            await this.Action_get_folders({
                id_obra:this.idGallery,
                idTipo: 7,
            });
        },
        crearCarpeta(){
            this.$refs.abrirAgregarCarpeta.toggle('galeria', this.idGallery)
        },
        editFolder(data){
            this.$refs.abrirAgregarCarpeta.toggle('galeria',this.idGallery,data)
        },
        deleteFolder(id){
            this.idFolderSelected = id
            this.$refs.abrirEliminarCarpeta.toggle()
        },
        agregarImg(){
            this.$refs.abrirAgregarImagenUnica.toggle()
        },
        editImg(data){
            this.$refs.abrirAgregarImagenUnica.toggle(data)
        },
        deleteImg(id_file){
            this.fileToDelete = id_file
            this.$refs.abrirEliminarImagen.toggle()
        },
        async handleDeleteFolder(){
            await this.Action_delete_folder({idProyecto: this.idGallery,idCarpeta: this.idFolderSelected})
            this.listFolders()
        },
        async loadFolderFiles(id){
            this.idFolderSelected = id
            await this.Action_get_folders_files({ idProyecto: this.idGallery, idCarpeta:id})
            this.selectedFolderFiles = this.folderFilesList.data
        },
        async handleDeleteFolderFile(){
            await this.Action_delete_folder_file({ 
                idProyecto: this.idGallery,
                idCarpeta: this.idFolderSelected,
                idArchivo: this.fileToDelete
            })
            //await this.Action_get_folders_files({idProyecto: this.idGallery, idCarpeta: this.idFolderSelected})
            this.selectedFolderFiles = this.folderFilesList.data
            this.listFolders()
        },
        async handleAddImgFile(data){
            
            const base64Image = data.image
            
            if(base64Image) {
                await this.uploadFile({ 
                    file: await this.base64ToFile(base64Image),
                    path: '/obras/galeria' 
                });
            }
            
            let payload = {
                idProyecto: this.idGallery, 
                idCarpeta: this.idFolderSelected,
                nombre: data.name,
                id_tipo: 2,
                archivo: this.key,
                visualizador_archivo: 1
            } 
            await this.Action_save_folder_file(payload)
            
            this.listFolders()
            this.loadFolderFiles(this.idFolderSelected)
        },
        handleEditImgFile(data){
            console.log('editando data file',data);
        },
        verGaleria(index){
            const imagenes = this.selectedFolderFiles.map(e => e.archivo)
            this.$refs.openModalGallery.toggle(imagenes, 'Ver galeria', index)
        }
    }
}
</script>

<style lang="scss" scoped>
.obras-ver-galeria{
    height: calc(100vh - 385px);
    .listado-carpetas{
        //min-height: 400px;
        max-height: 400px;
        .carpeta{
            .cant-archivos{
                height: 16px;
            }
            &:hover{
                background: var(--color-general);
                color: #fff !important;
                cursor: pointer;
                .cant-archivos{
                    background: white;
                    color: var(--color-general) !important;
                }
            }
        }
    }
    .listado-galeria{
        //min-height: 400px;
        max-height: 400px;
        .images{
            position: relative;
            width: 138px;
            height: 159px;
            transition: .35s;
            .icon-dots-vertical{
                transition: .35s;
                position: absolute;
                position: absolute; 
                top: 2px; 
                right: -10px; 
                font-size: 22px; 
                opacity: 0.5;
            }
            .hover-card{
                background: #4444448f;
                position: absolute;
                display: none;
                transition: .35s;
            }
            &:hover{
                box-shadow: 0px 3px 6px #00000029;
                transition: .7s;
                .icon-dots-vertical{
                    opacity: 1;
                }
                .hover-card{
                    display: flex;
                    transition: .7s;
                }
            }
        }
    }
}
</style>